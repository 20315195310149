import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title="TermsOfService" />
			<hr />
			<h2>利用規約</h2>
			<div className="common-contents-block-wrapper">
				当サイトのご利用にあたって
				<br />
				当サイトに関し、事前に本ページをお読みいただき、ご理解くださいますようお願い申し上げます。
			</div>
			<hr />
			<h2>当サイト掲載コンテンツについて</h2>
			<div className="common-contents-block-wrapper">
				1）当サイト上に掲載される一切の写真、イラスト、映像、デザイン、音楽及び文章など（以下、総称して「コンテンツ」といいます）に関する著作権その他の知的財産権は、当社または当社が権利を譲渡した権利者に帰属します。
				<br />
				2）当社の許諾を得ることなく、コンテンツを複製、翻訳、翻案、放送、出版、販売、貸与、公衆送信、送信可能化などに利用すること、また改変、編集等の変更を加えることは、著作権法により原則として禁止されています。
				<br />
				3）コンテンツの利用をご希望される場合は、必ず事前に下記連絡先にご連絡の上、当社の許諾を得ていただくようお願いします。当社の許諾を得てコンテンツを利用する場合、著作権表示や利用態様などについては、当社の指示を遵守していただきます。なお、当社が不適当と判断する場合にはご利用をお断りする場合がございますのでご了承ください。
			</div>
			<hr />
			<h2>当社商標について</h2>
			<div className="common-contents-block-wrapper">
				当サイトに表示される商標、ロゴマークに関しては当社または当社が使用を認めた権利者に権利が帰属します。権利者に無断で使用することはできません。
			</div>
			<hr />
			<h2>当サイトへのリンク及び利用状況把握について</h2>
			<div className="common-contents-block-wrapper">
				1）当サイト上に掲載された情報やURLは、予告なしに変更、削除することがあります。また当社は、当サイトのコンテンツについて内容の正確性や有用性などにつき、何らの責任を負うものではありません
				<br />
				2）当サイトからもしくは当サイトへリンクを貼っている、当社以外の第三者のサイト（以下「リンクサイト」という）はそれぞれの責任で管理されているものであり、当社の管理下にあるものではありません。当社はリンクサイトの内容について、また、それらをご利用になったことによるいかなる損害についても責任を負いません。
				<br />
				3）当サイトにリンクを希望する方は、下記よりお問い合わせください。その際は当社に一切損害を与えないよう十分にご配慮ください。当サイトへのリンクにより当社が損害を被った場合には、損害賠償を請求することがあります。
				<br />
				4）当サイトへのリンクは、リンクを行った者に対して、当サイト上のコンテンツに関し、当社から何らの権利を付与するものではありません。
				<br />
				5）以下のリンク、またはそのおそれのあるリンクはお断りします。
				<br />
				・当社の事業目的の趣旨に沿わないと当社が判断するもの
				<br />
				・当社またはその関連会社、それらの役員または社員を誹謗・中傷するようなもの
				<br />
				・違法な内容・公序良俗に反するもの、または当社の信用や品位を損なうもの
				<br />
				・フレームリンクなど、当サイトの明確性が損なわれる可能性のあるもの
				<br />
				・当社と何らかの提携または協力関係にあるものと誤認を生じさせ、または当社がリンク元のサイトを認知もしくは支持しているとの誤認を生じさせるもの
				<br />
				・その他当社が不適切と判断するもの
				<br />
				6）本サイトは、サイトの利用状況を把握するためにGoogleAnalyticsによって提供されるサービスを利用しております。このため、一部のページにおいてGoogleAnalyticsから提供されるクッキーを使用しています。クッキーの使用に関する説明、クッキーによって収集される情報について詳しくお知りになりたい方は、Google
				Analyticsのプライバシーポリシーをご確認ください。
			</div>
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
				}
			}
		}
	}
`;
